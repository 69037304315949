/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type Model from '@ember-data/model';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import {
  KNOWLEDGE_HUB_DEFAULT_THEME_COLOR,
  DRAWER_EDITOR_SHOW_SIDE_PANEL,
} from 'embercom/lib/knowledge-hub/constants';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import storage from 'embercom/vendor/intercom/storage';

interface Args {
  activeContent: LocalizedKnowledgeContent & Model;
}

export default class Edit extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare appService: any;
  @service declare permissionsService: any;

  @trackedInLocalStorage({
    keyName: DRAWER_EDITOR_SHOW_SIDE_PANEL,
  })
  showSidePanel: boolean;

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.showSidePanel = storage.get(DRAWER_EDITOR_SHOW_SIDE_PANEL) ?? true;

    // Front end fix to enable customers to publish articles https://github.com/intercom/intercom/issues/367694
    if (!this.args.activeContent?.writer) {
      this.args.activeContent.writer = this.appService.app.currentAdmin;
    }
  }

  get defaultThemeColor(): string {
    return KNOWLEDGE_HUB_DEFAULT_THEME_COLOR;
  }

  get editorConfig(): KnowledgeHubEditorConfig | undefined {
    return this.args.activeContent && this.args.activeContent.editorConfig
      ? this.args.activeContent.editorConfig
      : undefined;
  }

  get paywallConfig() {
    return this.knowledgeHubEditorService.paywallConfig;
  }

  @action
  toggleSidePanel(): void {
    this.showSidePanel = !this.showSidePanel;
    this.knowledgeHubEditorService.trackAnalyticsEvent(
      `${this.showSidePanel ? 'show' : 'hide'}_details_side_panel`,
    );
  }

  @action
  cancelEditActiveContent(): void {
    this.knowledgeHubEditorService.cancelEditActiveContent();
  }

  @dropTask *saveActiveContent(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(
        this.args.activeContent.requiredEditPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    yield this.knowledgeHubEditorService.saveActiveContent();
  }

  @restartableTask *autoSaveActiveContent(): TaskGenerator<void> {
    if (
      !this.permissionsService.currentAdminCan(
        this.args.activeContent.requiredEditPermissionForKnowledgeHub,
      ) ||
      !this.args.activeContent?.autoSaveEnabled ||
      !this.args.activeContent?.hasUnsavedChanges ||
      !this.args.activeContent?.validations?.isValid ||
      // prevents auto-save (flashing) when version history overlay is open - see https://github.com/intercom/intercom/issues/361362
      this.knowledgeHubEditorService.showHistoryOverlay
    ) {
      return;
    }

    yield timeout(ENV.APP._5000MS);

    yield this.knowledgeHubEditorService.autoSaveContent();
  }

  @dropTask
  *publishActiveContent(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(
        this.args.activeContent.requiredPublishPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    yield this.knowledgeHubEditorService.publishActiveContent();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Edit': typeof Edit;
    'knowledge-hub/content-editor/edit': typeof Edit;
  }
}
