/* import __COLOCATED_TEMPLATE__ from './suggestion-editor.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import type Model from '@ember-data/model';
import { action } from '@ember/object';
import { next, schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';
import type { Block } from '@intercom/interblocks.ts';
import type ContentReviewRequest from 'embercom/models/content-service/content-review-request';
import { EntityType } from 'embercom/models/data/entity-types';
import type { LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type SuggestionService from 'embercom/services/suggestion-service';
import { ReviewType } from 'embercom/models/content-service/content-review-request';

interface SuggestionEditorArgs {
  reviewRequests: ContentReviewRequest[];
  activeContent: LocalizedKnowledgeContent & Model;
}

export type BlockGroup = {
  id: string;
  isEdited: boolean;
  blocksBefore?: Block[];
  blocks: Block[];
  reviewRequest?: ContentReviewRequest;
  composerApi?: ComposerPublicAPI;
};

export default class SuggestionEditor extends Component<SuggestionEditorArgs> {
  @service declare appService: $TSFixMe;
  @service declare suggestionService: SuggestionService;

  @tracked showPreviousVersion = false;
  @tracked declare updatedBlocksForIndex: Block[];
  @tracked declare focusedComposer?: Composer;

  constructor(owner: any, args: SuggestionEditorArgs) {
    super(owner, args);
    this.suggestionService.createBlockGroups(this.args.activeContent, this.args.reviewRequests);
    schedule('afterRender', () => {
      next(() => {
        this.scrollToFirstEditedBlockGroup();
      });
    });
  }

  get isArticleContent() {
    return this.args.activeContent.entityType === EntityType.ArticleContent;
  }

  get isContentSnippet() {
    return this.args.activeContent.entityType === EntityType.ContentSnippet;
  }

  get blockGroups(): BlockGroup[] {
    return this.suggestionService.blockGroups;
  }

  @action
  scrollToFirstEditedBlockGroup() {
    if (this.editedBlockGroups.length > 0) {
      this.scrollToBlockGroup(this.editedBlockGroups[0]);
    }
  }

  scrollToBlockGroup(blockGroup: BlockGroup) {
    let blockGroupElement = document.getElementById(blockGroup.id);
    if (blockGroupElement) {
      blockGroupElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  get editedBlockGroups(): BlockGroup[] {
    return this.blockGroups.filter((group) => group.isEdited);
  }

  get uneditedBlockGroups(): BlockGroup[] {
    return this.blockGroups.filter((group) => !group.isEdited);
  }

  get suggestionsToEditBlockGroups(): BlockGroup[] {
    return this.blockGroups;
  }

  @action
  toggleShowPreviousVersion() {
    this.showPreviousVersion = !this.showPreviousVersion;
  }

  @action
  setFocusedComposer(composer?: Composer) {
    this.focusedComposer = composer;
  }

  @action
  shouldShowSuggestionCard(blockGroup: BlockGroup): boolean {
    if (!blockGroup.reviewRequest?.get('reviewReason')) {
      return false;
    }
    return (
      (blockGroup.isEdited && blockGroup.reviewRequest?.get('status') === 0) ||
      blockGroup.reviewRequest?.get('reviewType') === ReviewType.CREATE_CONTENT
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::SuggestionEditor': typeof SuggestionEditor;
  }
}
