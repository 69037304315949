/* import __COLOCATED_TEMPLATE__ from './document360.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type SyncSteps } from 'embercom/components/knowledge-hub/filterable-list';
import type ImportSource from 'embercom/models/import-service/import-source';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { SourceType, SyncBehaviorType } from 'embercom/models/import-service/import-source';
import { get } from 'embercom/lib/ajax';
import type FolderSelector from 'embercom/components/knowledge-hub/folder-selector';
import { type FolderTreeItem } from 'embercom/components/knowledge-hub/folder-selector';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import truncateString from 'embercom/lib/truncate-string';

interface Args {
  modal: any;
  title?: string;
  onNavigationBack?: () => void;
  onNavigationNext?: (viewId: string) => void;
  showBackButton?: boolean;
  onModalClose: () => void;
  setSyncStep: (step: SyncSteps) => void;
  isReconfiguring?: boolean;
  existingImportSource?: ImportSource;
}

type Project = {
  id: string;
  name: string;
  language_versions: LanguageVersion[];
};

type LanguageVersion = {
  id: string;
  name: string;
  language_code: string;
};

export default class Document360SyncModal extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;

  @tracked importSource: ImportSource | undefined;
  @tracked selectedCollection?: string;

  @tracked hasCredentials = false;
  @tracked apiToken = '';
  @tracked hostingRegion = '';
  @tracked credentialsSubmitted = false;
  @tracked projects: { text: string; value: string }[] = [];
  @tracked languages: { text: string; value: string }[] = [];
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Sync;
  @tracked selectedProject?: string;
  @service declare appService: any;
  @tracked categoryTree: FolderTreeItem[] = [];
  @tracked selectedCategoryIds: Set<string> = new Set();
  @tracked supportedLanguagesForProject: LanguageVersion[] = [];
  @tracked allProjects: Project[] = [];
  @tracked selectedLanguageCodes: Array<string> = [];
  @tracked existingImportSource: ImportSource | undefined;
  @tracked isReauthenticating = false;
  @tracked sourceType = SourceType[SourceType.Document360];

  get importSourceId() {
    return this.importSource?.id;
  }

  get submitDisabled() {
    return (
      (this.hasCredentials && !this.selectedProject) ||
      this.apiToken.length === 0 ||
      this.hostingRegion.length === 0
    );
  }

  @action
  async submit() {
    if (this.args.isReconfiguring) {
      await this.startReauthenticating();
    } else if (this.hasCredentials) {
      await this.startSync();
    } else {
      await this.getAllProjects();
    }
  }

  async getAllProjects() {
    try {
      this.allProjects = await this.fetchProjects();
      this.projects = this.allProjects.map((project) => ({
        value: project.id,
        text: project.name,
      }));
      this.hasCredentials = true;
    } catch (error) {
      let errorMessage = this.intl.t('knowledge-hub.sync-modal.document360.invalid-credentials');
      this.notificationsService.notifyError(errorMessage);
    }
  }

  async startSync() {
    let selectedProject = this.projects.find((project) => project.value === this.selectedProject);

    let denyCategoryIds: string[] = [];
    if (this.selectedCategoryIds.size > 0) {
      denyCategoryIds = this.categoryTree
        .reject((category) => this.selectedCategoryIds.has(category.id))
        .map((c) => c.id);
    }

    let importSourceParams = {
      sourceType: SourceType.Document360,
      authToken: this.apiToken,
      denyFolderIds: denyCategoryIds,
      hostingRegion: this.hostingRegion,
      projectId: selectedProject?.value,
      languageCodes: Array.from(this.determineSelectedLanguageCodes),
      projectName: selectedProject?.text,
    };

    this.importSource = await this.knowledgeHubService.createImportSource(importSourceParams);

    try {
      await this.knowledgeHubService.startImport(this.importSource.id, this.syncBehavior);

      this.knowledgeHubService.notifySourceStateChangeConfirmation(
        this.intl.t('knowledge-hub.sync-modal.document360.sync-started'),
        this.importSource.id,
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        context: 'import-content',
        object: `import-content.document360`,
        section: `knowledge-hub.new-content.document360`,
        place: 'knowledge-hub.new-content',
      });
    } catch (error) {
      if (error.jqXHR?.status === 409) {
        this.notificationsService.notifyError(
          this.intl.t('knowledge-hub.sync-modal.document360.sync-already-in-progress'),
        );
      } else {
        throw error;
      }
    }

    this.args.onModalClose();
  }

  get determineSelectedLanguageCodes() {
    if (this.selectedLanguageCodes.length === 0) {
      return new Set(this.supportedLanguagesForProject.map((language) => language.language_code));
    }

    return this.selectedLanguageCodes;
  }

  get hostingRegions() {
    return [
      {
        text: 'EU',
        value: 'eu',
      },
      {
        text: 'US',
        value: 'us',
      },
    ];
  }

  get categorySelectorLabel() {
    if (this.selectedCategoryIds.size === 0) {
      return this.intl.t('knowledge-hub.sync-modal.document360.category.placeholder');
    }

    return undefined;
  }

  get projectSelectorLabel() {
    return this.intl.t('sync-modal.document360.project.label');
  }

  get conditionalLabel() {
    let languageCodes = Array.from(this.selectedLanguageCodes);

    if (!this.selectedProject || languageCodes.length === 0) {
      return this.intl.t('knowledge-hub.sync-modal.document360.language.placeholder');
    } else {
      return truncateString(
        Array.from(this.selectedLanguageCodes)
          .map((language: string) => {
            return this.supportedLanguagesForProject.find((l) => l.language_code === language)
              ?.name;
          })
          .join(', '),
      );
    }
  }

  @action
  async startReauthenticating() {
    try {
      let importSource = this.args.existingImportSource;
      if (!importSource) {
        throw new Error('Import source not found');
      }

      importSource.authToken = this.apiToken;
      importSource.hostingRegion = this.hostingRegion;

      await importSource.save();

      await this.knowledgeHubService.startImport(importSource.id, this.syncBehavior);

      this.notificationsService.notifyConfirmation(
        this.intl.t('knowledge-hub.sync-modal.document360.re-authenticate-success'),
      );

      this.knowledgeHubService.notifySourceStateChangeConfirmation(
        this.intl.t('knowledge-hub.sync-modal.document360.sync-started'),
        importSource.id,
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'document_360_credentials',
        section: 'knowledge-hub',
        place: 'document360_modal',
      });

      this.args.onModalClose();
    } catch (error) {
      console.error('Error reauthenticating', error);
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.sync-modal.document360.re-authenticate-error'),
      );
    }
  }

  @action
  updateSelectedProject(projectId: string) {
    this.selectedProject = projectId;

    let selectedProject = this.allProjects.find((project) => project.id === projectId);

    this.supportedLanguagesForProject = selectedProject?.language_versions || [];

    this.languages = this.supportedLanguagesForProject.map((language) => ({
      value: language.language_code,
      text: language.name,
    }));
  }

  @action
  updateSelectedCategory(categoryIds: Set<string>) {
    this.selectedCategoryIds = categoryIds;
  }

  @action
  updateSelectedLanguage(languageCodes: Array<string>) {
    this.selectedLanguageCodes = languageCodes;

    this.selectedCategoryIds = new Set();

    taskFor(this.fetchCategoryTree).perform();
  }

  async fetchProjects(): Promise<Project[]> {
    let response = await get('/ember/import_service/import_sources/document360/projects', {
      app_id: this.appService.app.id,
      auth_token: this.apiToken,
      hosting_region: this.hostingRegion,
    });

    let json = response as unknown as Project[];
    return json;
  }

  async fetchCategoryTreeSnapshot(): Promise<FolderTreeItem[]> {
    let response = await get('/ember/import_service/import_sources/document360/folder_tree', {
      app_id: this.appService.app.id,
      auth_token: this.apiToken,
      project_id: this.selectedProject,
      selected_language_codes: Array.from(this.selectedLanguageCodes),
    });

    let json = response as unknown as FolderTreeItem[];
    return json;
  }

  get isLoadingCategoryTree() {
    return taskFor(this.fetchCategoryTree).isRunning;
  }

  @dropTask
  *fetchCategoryTree(): TaskGenerator<void> {
    this.categoryTree = yield this.fetchCategoryTreeSnapshot();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::Document360': typeof Document360SyncModal;
    'KnowledgeHub::FolderSelector': typeof FolderSelector;
    'knowledge-hub/setup-modal/sync-modal/document360': typeof Document360SyncModal;
  }
}
